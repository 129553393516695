import React from "react";
import history from "src/browserHistory";
import Alerts from "./Alerts";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import ButtonCard from "./ButtonCard";
import Cards from "./Cards";
import Dropdowns from "./Dropdowns";
import Pagination from "./Pagination";
import Progress from "./Progress";
import Tabs from "./Tabs";
import Typography from "./Typography";
import Accordions from "./Accordions";
import Avatar from "./Avatar";
import Badges from "./Badges";
import ButtonGroup from "./ButtonGroup";
import Grid from "./Grid";
import Images from "./Images";
import Media from "./Media";
import Modals from "./Modals";
import Offcanvas from "./Offcanvas";
import { Route, Router } from "react-router-dom";

const Components = () => {
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Components"
            title="Dashboard"
            subtitle="Components"
          />
          {/* /Page Header */}
            <Router location={""} navigator={history}>
                <Alerts />
                <Accordions />
                <Route path="/accordions" element={<Accordions />} />

                <Avatar />
                <Badges />
                <ButtonCard />
                <ButtonGroup />
                <Cards />
                <Dropdowns />
                <Grid/>
                <Images/>
                <Media/>
                <Modals/>
                <Offcanvas/>
                <Pagination />
                <Progress />
                <Tabs />
                <Typography />
            </Router>

        </div>
        {/* /Page Content */}
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default Components;
